import * as React from "react";
import { Link } from "react-router-dom";

const Logo: React.FC = () => (
  <Link to="/" className="Logo">
    <div id="logo">
      <svg version="1.1" width="300" height="81.386726" id="svg3262">
        <g transform="translate(-384.28572,-428.81172)" id="layer1">
          <g
            transform="matrix(2.5445375,0,0,2.5445375,1157.1714,-1457.8678)"
            id="g3235"
          >
            <path
              d="m -203.09972,771.41415 c 1.6425,0.15875 3.2825,0.33 4.92,0.5075 l 3.615,-8.92625 3.43625,9.74875 c 1.76375,0.22125 3.525,0.4525 5.2825,0.695 l -6.02375,-17.09625 6.02625,-14.88 -5.10375,0 -0.0525,0.0725 -3.255,8.03875 -2.8575,-8.11125 -5.03875,0 5.2025,14.7625 -6.15125,15.18875 z"
              id="path3015"
              style={{
                fill: "#b81d24",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
              }}
            />
            <path
              d="m -206.91147,771.06478 0,-29.60125 -5.0375,0 0,29.18625 c 1.68125,0.12875 3.36125,0.26875 5.0375,0.415"
              id="path3019"
              style={{
                fill: "#b81d24",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
              }}
            />
            <path
              d="m -244.7486,769.4089 c 1.36,0 2.7175,0.01 4.07375,0.0213 l 0,-10.875 6.05125,0 0,-4.63125 -6.05125,0 0,-7.825 6.96875,0 0,-4.63625 -12.02625,0 0,27.95 c 0.3275,0 0.655,-0.004 0.98375,-0.004"
              id="path3023"
              style={{
                fill: "#b81d24",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
              }}
            />
            <path
              d="m -260.3881,769.69191 c 1.6775,-0.06 3.3575,-0.11 5.04,-0.15125 l 0,-23.44125 4.7075,0 0,-4.63625 -14.45625,0 0,4.63625 4.70875,0 0,23.5925 z"
              id="path3035"
              style={{
                fill: "#b81d24",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
              }}
            />
            <path
              d="m -298.91059,772.81378 0,-17.63625 5.96375,16.92375 c 1.83375,-0.20625 3.67125,-0.4 5.5125,-0.5825 l 0,-30.055 -4.8325,0 0,18.2675 -6.43625,-18.2675 -0.2075,0 -4.8325,0 0,31.98375 0.03,0 c 1.5975,-0.22125 3.19875,-0.43125 4.8025,-0.63375"
              id="path3039"
              style={{
                fill: "#b81d24",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
              }}
            />
            <path
              d="m -269.953 746.099 l 0 -4.6363 l -12.0275 0 l 0 24.9125 l 0 4.6375 l 0 0.004 c 3.9912 -0.345 7.9962 -0.6338 12.0175 -0.8688 l 0 -0.004 z"
              id="path3051"
              style={{
                fill: "#b81d24",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
              }}
            />
            <path
              d="m -223.72272,765.2864 0,-23.82375 -5.05875,0 0,23.605 0,4.63625 0,0.005 c 4.02375,0.1475 8.0325,0.35375 12.0275,0.6125 l 0,-0.006 0,-1.4975 0,-3.13875 c -2.31875,-0.15 -4.64125,-0.28 -6.96875,-0.3925"
              id="path3055"
              style={{
                fill: "#b81d24",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
              }}
            />
          </g>
        </g>
      </svg>
    </div>
  </Link>
);

export default Logo;
